<template>
  <b-row>
    <b-col>
      <slot name="botoes-adicionais" />
      <b-btn
        v-if="opcoes.novo && opcoes.novo.visible !== false"
        v-b-tooltip.hover
        variant="none"
        class="btn btn-outline-primary mr-1"
        size="sm"
        :title="opcoes.NotificarLimite ? opcoes.LimiteMensagem : ''"
        @click="novo(linhas)"
      >
        <feather-icon
          style="margin-right:0.25rem"
          icon="PlusIcon"
        />Novo
      </b-btn>
      <b-btn
        v-if="opcoes.favoritar && opcoes.favoritar.visible !== false"
        variant="none"
        class="btn btn-outline-primary mr-1"
        size="sm"
        :disabled="!linhas || linhas.length == 0"
        @click="favoritar(linhas)"
      >
        <feather-icon
          icon="StarIcon"
          class="text-warning"
        />
        Favoritar
      </b-btn>
      <!-- v-if="mostrarRemover" -->
      <b-btn
        v-if="opcoes.remover && opcoes.remover.visible !== false"
        variant="none"
        size="sm"
        class="btn btn-outline-danger"
        :disabled="!linhas || linhas.length == 0"
        @click="remover(linhas)"
      >
        <feather-icon
          style="margin-right:0.25rem"
          icon="TrashIcon"
        />Remover
      </b-btn>
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import {
  defineComponent, reactive,
} from '@vue/composition-api'

export default defineComponent({
  props: {
    opcoes: {
      type: Object,
      required: true,
    },
    mostrarRemover: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      linhas: [],
    }
  },
  setup(props, context) {
    const opt = reactive(props.opcoes)
    const toast = useToast()

    const novo = linhasSelecionadas => {
      opt.novo.evento(linhasSelecionadas)
    }

    const favoritar = linhasSelecionadas => {
      opt.favoritar.evento(linhasSelecionadas)
        .catch(err => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível favoritar as informações',
              icon: 'fa-solid fa-circle-exclamation',
              variant: 'danger',
            },
          })
          throw err
        })
    }

    const remover = linhasSelecionadas => {
      const contemVarios = Array.isArray(linhasSelecionadas) ? linhasSelecionadas.length : 0
      if (!contemVarios || contemVarios.length === 0) {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Selecione pelo menos um usuário',
            icon: 'fa-solid fa-circle-exclamation',
            variant: 'danger',
          },
        })
      } else {
        Vue.swal({
          title: 'Remover?',
          text: `Tem certeza que deseja remover ${contemVarios > 1 ? 'os registros' : 'o registro'}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          customClass: {
            confirmButton: 'btn btn-outline-primary mr-50',
            cancelButton: 'btn btn-outline-warning ',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              opt.remover.evento(linhasSelecionadas)
                .then(() => {
                  context.emit('removido')
                  Vue.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Removido!',
                      icon: 'fa-solid fa-circle-check',
                      variant: 'success',
                    },
                  })
                })
                .catch(err => {
                  Vue.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Não foi possível remover as informações',
                      icon: 'fa-solid fa-circle-exclamation',
                      variant: 'danger',
                    },
                  })
                  throw err
                })
            }
          })
      }
    }

    return {
      novo,
      remover,
      favoritar,
    }
  },
})
</script>
